@tailwind base;
@tailwind components;
@tailwind utilities;

/* add the code bellow */
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@font-face {
  font-family: "Mango";
  src: url("../src/Component/asstes/Fonts/GreatMango-Regular.ttf");
}

@font-face {
  font-family: "Twemoji Country Flags";
  unicode-range: U+1F1E6-1F1FF, U+1F3F4, U+E0062-E0063, U+E0065, U+E0067,
    U+E006C, U+E006E, U+E0073-E0074, U+E0077, U+E007F;
  src: url("https://cdn.jsdelivr.net/npm/country-flag-emoji-polyfill@0.1/dist/TwemojiCountryFlags.woff2")
    format("woff2");
}

/* ------------------------------------------- */
.slick-prev:before {
  color: black !important;
}
.slick-next:before {
  color: black !important;
}
/* ------------------------------------------ */

.text {
  font-weight: 600;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 15px;
  fill: gray;
}

#rotatingText {
  animation-name: rotate-circle;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

#rotatingText:hover {
  animation-name: hover-rotate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

@keyframes rotate-circle {
  to {
    transform: rotate(1turn);
  }
}

@keyframes hover-rotate {
  to {
    transform: rotate(360deg);
  }
}

.main {
  display: grid;
  align-items: center;
  justify-items: center;
  box-shadow: 10px solid white;
}

.main p {
  position: absolute;
  background: linear-gradient(226deg, #1c9cff, #4e22ff);
  padding: 30px;
}
