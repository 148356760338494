.h1{
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
    font-weight: 600;
    margin-bottom: 1rem; /* 16px */
}

.h2{
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
    font-weight: 600;
    margin-top: 1rem; /* 16px */
    margin-bottom: 0.5rem; /* 8px */
}

.h3{
    font-weight: 500;
    margin-top: 1.5rem; /* 24px */
    margin-bottom: 0.5rem; /* 8px */
}

.ol{
    list-style-type: decimal;
    font-weight: 500;
    list-style-position: inside;
    margin-bottom: 1rem; /* 16px */
}

.ul{
    list-style-type: disc;
    font-weight: 400;
    list-style-position: inside;
    margin-left: 1rem; /* 16px */
}

.li{
    margin-bottom: 1rem; /* 16px */
}

.p{
    margin-bottom: 1rem; /* 16px */
    font-weight: 400;
}

.span{
    font-weight: 500;
    margin-right: 0.5rem; /* 8px */
}